@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
  font-family: "Work Sans", sans-serif;
}
